import React, { useState, useEffect, useLayoutEffect, lazy, Suspense } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { YMInitializer } from 'react-yandex-metrika'
import ReactGA from "react-ga4"
import { MenuUnfoldOutlined, CloseSquareOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import Header from '../Header/Header'
import Content from './Content'
import Articles from '../../layouts/Articles/Articles'
import Technologies from '../../layouts/Technologies/Technologies'
import ModalWrapper from '../../wrapers/ModalWrarrer/ModalWrapper'
import ModalEmail from '../../wrapers/ModalEmail/ModalEmail'
import Messengers from '../../layouts/Messengers/Messengers'
import { closeModal, showAlertAction } from '../../core/actions/closeModalAction'
import ModalAlert from '../../layouts/ModalAlert/ModalAlert'
import Envelop from '../../layouts/Envelop/Envelop'

import style from './CreateSite.module.scss'

const ContentMobile = lazy(() => import('./Content'))
const HeaderMobile = lazy(() => import('../Header/Header'))


export default function CreateSite() {

  const [mobile, setMobile] = useState(false)
  useLayoutEffect(() => {
    const userAgent = navigator.userAgent
    const isMobile = /mobile|iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(userAgent)
    setMobile(isMobile)
  }, [])
  
  const dispatch = useDispatch()
  const dropModal = useSelector(({ closeModal: { showModal } }) => showModal)
  const dropAlert = useSelector(({ closeModal: { showAlert } }) => showAlert)
  const showContentReduser = useSelector(({ closeModal: { showContent } }) => showContent)
  const showModalReduser = useSelector(({ closeModal: { showModalContent } }) => showModalContent)

  const [touch, setTouch] = useState(null)
  const [showHeader, setShowHeader] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [showDiscount, setShowDiscount] = useState(false)
  const [showContent, setShowContent] = useState('')
  const [riseSite, setRiseSite] = useState(false)

  useEffect(() => {
    setRiseSite(true)
    setTimeout(() => {
      setRiseSite(false)
    }, 800)
  }, [])
  
  useEffect(() => {
    setTimeout(() => {
      setShowDiscount(true)
    }, 3000)
  }, [])

  useEffect(() => {
    setShowModal(showModalReduser)
  }, [showModalReduser])

  useEffect(() => {
    setShowContent(showContentReduser)
  }, [showContentReduser])

  useEffect(() => {
    setShowModal(dropModal)
    dispatch(closeModal(null))
  }, [dropModal])

  useEffect(() => {
    setShowAlert(dropAlert)
    setTimeout(() => {
      setShowAlert(false)
      dispatch(showAlertAction(null))
    }, 1600)
  }, [dropAlert])

  const onTouch = (e) => {
    setTouch(e)
  }

  const onShowModal = (arg) => {
    setRiseSite(true)
    setShowContent(arg)
    setTimeout(() => {
      setRiseSite(false)
      setShowModal(true)
    }, 600)
  }
  const onEnvelop = () => {
    onShowModal('title')
    setTimeout(() => {
      setRiseSite(false)
      setShowModal(true)
    }, 600)
  }
  const onCloseModal = () => {
    dispatch(closeModal(false))
    setTimeout(() => {
      setShowModal(false)
    }, 50)
  }
  const getSite = () => {
    onShowModal('title')
  }
  const onOrder = () => {
    setShowDiscount(false)
    setShowModal(true)
  }

  ReactGA.initialize('G-HY9F5NS8D1')

  return (
    <>
      {
        mobile ?
        <div className={showHeader ? style.wrapShowHeader : style.wrapCloseHeader}>
          <Suspense fallback={<Spin/>}>
            <HeaderMobile/>
          </Suspense>
        </div>
        : <Header/>
      }
      <div className={style.wrapper}
        onTouchStart={(e) => onTouch(e)}
      >
        {
          mobile ? (
            showHeader ?
            <CloseSquareOutlined 
              className={style.burger}
              style={{fontSize: 36}}
              onClick={() => setShowHeader(false)}
            />
            : <MenuUnfoldOutlined 
                className={style.burger}
                onClick={() => setShowHeader(true)}
              /> 
          )
          : ''
        }
        <div className={style.wrapOrder} >
          <div className={style.wrapContent}>
            <div className={style.wrapImg}
              onClick={() => getSite()}
            >
              <img src='site.webp' width='280' height='254' className={riseSite ? style.riseImg : style.img} alt='mobile app'/>
              {/* Заказывай разработку мобильных приложений у меня. Это выгодно и безопасно.  Я профессионал в программировании. */}
            </div>
            <h1 className={style.mainTitle} async media="print">Заказывай разработку мобильных приложений у меня. Это выгодно и безопасно.</h1>
            <h1 className={style.mainTitle} async media="print">Заказать мобильное приложение</h1>
            <h2 className={style.mainTitle} async media="print">Заказать интернет магазин</h2>
            <h2 className={style.mainTitle} async media="print">Разработка мобильного приложения в Минске</h2>
            <p className={style.title}>
              <span className={style.titleColor} async media="print">MOBILE APP</span> &nbsp; ONLINE STORE &nbsp; CORPORATE WEBSITE
            </p>
            <span className={style.content} async media="print">
              &nbsp; &nbsp; Хотите владеть <span className={style.app}>мобильным приложением</span>, уникальным и продающим интернет-магазином или корпоративным сайтом, которые выделяют вас на фоне конкурентов, обозначают ваш статус и делают ваши продажи более эффективными? 
              <br/> &nbsp; &nbsp; Отличные новости - вы находитесь именно в том месте, где это можно осуществить!
              <br/> &nbsp; &nbsp; У нас вы можете заказать впечатляющий и привлекательный веб-ресурс, способный привлечь массу клиентов и сделать ваш бизнес успешным. 
            </span>
            <span className={style.contentResetMobile} async media="print">
              <br/> &nbsp; &nbsp; Повышайте свою видимость в онлайн-мире и доверьте нам разработку уникального и продающего сайта или интернет-магазина.
            </span>
          </div>
        </div>
        <div className={style.wrapBtn}>
          <button className={style.btn}
              onClick={() => getSite()}
            >submit app
          </button>
          <Messengers
            direction='row'
            size='40px'
          />
        </div>
        <Technologies
          fontFamily = {true}
        />
        <div className={style.wrapSites}>
          <div className={style.wrapTitle}>
            <h2 className={style.title}>Цена сайта в Минске</h2>
          </div>
          <div className={style.wrapSite}>
            <div className={style.site}>
              <span className={style.text}>Landing page</span>
            </div>
            <div className={style.contentSite}>
              <span className={style.text}>
              1 страница. До 10 позиций товаров и услуг. Портфолио. <br/>
              Формы обратной связи. Акции и спецпредложения.
              </span>
            </div>
            <div className={style.wrapBtnSite}>
              <div className={style.btn}
                onClick={() => onShowModal('Landing page')}
              >от 800 р.</div>
            </div>
          </div>
          <div className={style.wrapSite}>
            <div className={style.site}>
                <span className={style.text}>Сайт-визитка</span>
            </div>
            <div className={style.contentSite}>
              <span className={style.text}>
              1-5 страниц. Небольшое количество товаров и услуг. Портфолио. <br/>
              Формы обратной связи. Акции и спецпредложения.
              </span>
            </div>
            <div className={style.wrapBtnSite}>
              <div className={style.btn}
                onClick={() => onShowModal('Сайт-визитка')}
              >от 1200 р.</div>
            </div>
          </div>
          <div className={style.wrapSite}>
          <div className={style.site}>
              <span className={style.text} >Корпоративный <br/> сайт</span>
          </div>
          <div className={style.contentSite}>
              <span className={style.text}>
              5 страниц и более. Страницы с описанием товаров или услуг.<br/>
              Формы обратной связи. Информация о компании или история бренда. Индивидуальный дизайн. Портфолио.<br/>
              Личный кабинет с выбором роли. Новости, пресс-релизы, акции, анонсы мероприятий.
              </span>
          </div>
          <div className={style.wrapBtnSite}>
              <div className={style.btn}
              onClick={() => onShowModal('Корпоративный сайт')}
              >от 1800 р.</div>
          </div>
          </div>

          <div className={style.wrapSite}>
          <div className={style.site}>
              <span className={style.text}>Интернет <br/> магазин</span>
          </div>
          <div className={style.contentSite}>
              <span className={style.text}>
              Большое количество товаров и услуг. Индивидуальный дизайн. Формы обратной связи.<br/>
              Корзина товаров. Оплата товаров на сайте.<br/>
              Личный кабинет. Поиск и фильтрация. Акции и спецпредложения.
              </span>
          </div>
          <div className={style.wrapBtnSite}>
              <div className={style.btn}
              onClick={() => onShowModal('Интернет магазин')}
              >от 2600 р.</div>
          </div>
          </div>
          <div className={style.wrapSite}>
          <div className={style.site}>
              <span className={style.text}>Мобильное <br/> приложение</span>
          </div>
          <div className={style.contentSite}>
              <span className={style.text}>
              Разработка в соответсвии с согласованными ТЗ и макетами в Фигме.<br/>
              Индивидуальный дизайн. Формы обратной связи.<br/>
              В случае разработки приложения для продаж: корзина товаров, оплата товаров, 
              поиск и фильтрация, акции и спецпредложения.<br/>
              Настройка рассылки<br/>
              Размещение в App Store и Google Play
              </span>
          </div>
          <div className={style.wrapBtnSite}>
              <div className={style.btn}
              onClick={() => onShowModal('Мобильное приложение')}
              >от 3800 р.</div>
          </div>
          </div>
        </div>
        <Articles/>
        {
          mobile ? (
            touch != null ?
              <Suspense fallback={<Spin/>}>
                <ContentMobile/>
              </Suspense>
            : ''
          )
          : <Content/>
        }  

        <ModalWrapper showModal={showModal}>
          <div className={style.wrapModal}>
            <div className={style.wrapTitle}>
              <span className={style.tytle}>Форма обратной связи</span> 
              <div
                className={style.close}
                onClick={() => onCloseModal()}
              ><img src={require('./images/X.png')} loading='lazy' style={{ width: 19, height: 18 }} alt="X" draggable="false"/>
              </div>
            </div>
            <ModalEmail>
              {
                showContent === 'title' 
                  ? <>
                    <label className={style.label}>Опишите какой сайт вас интересует</label>
                    <textarea 
                      className={style.textarea} 
                      name="message"  
                      required/>
                  </>
                  : <>
                    <label className={style.label}>Ваш выбор</label>
                    <textarea 
                      className={style.textarea} 
                      name="message" 
                      value={showContent} 
                      onChange={(e) => setShowContent(e.target.value)}/>
                  </>  
              }
            </ModalEmail>
          </div>
        </ModalWrapper>

        <ModalWrapper showDiscount={showDiscount}>
          <div className={style.wrapDiscount}>
            <div className={style.close}
              onClick={() => setShowDiscount(false)}
            >
              <img src={require('./images/X.png')} loading='lazy' style={{ width: 19, height: 18 }} alt="X" draggable="false"/>
            </div>
            <span className={style.title}>Discount</span>
            <span className={style.textDiscount}>2 Мобильных приложения</span>
            <span className={style.textDiscount}>по цене 1-го</span>
            <span className={style.textDiscount}>+</span>
            <span className={style.textDiscount}>Сайт в подарок</span>
            <div className={style.btnDiscount}
              onClick={onOrder}
            >Заказать</div>
          </div>
        </ModalWrapper>

        <ModalAlert showAlert={showAlert}/>
        <div
          onClick={() => onEnvelop()}
        >
          <Envelop 
            size={mobile ? 85 : 290}
            position={mobile ? 'absolute' : 'fixed'}
          />
        </div>
      </div>
      <div defer>
        <YMInitializer 
          accounts={[94127841]} 
          options={{defer: true}} 
          //version="2"
        />
        { ReactGA.event({ hitType: "pageview", page: "../CreateSite/CreateSite.jsx", title: "Заказать мобильное приложение" })}
      </div>
    </>
  )
}


